<template>
  <div class="tale-main-page">
    <PageHeader>
      <template #right>
        <el-button
          type="primary"
          size="small"
          icon="el-icon-plus"
          @click="addRow"
          >新增</el-button
        >
      </template>
    </PageHeader>

    <div class="page-table">
      <basic-table
        showSelection
        hiddenClear
        :loading="tableLoading"
        :tableColumns="tableColumns"
        :tableData="tableData"
        :page="page"
        :size="size"
        :total="total"
        :orderDirection="orderDirection"
        :orderProp="orderProp"
        @onSortChange="onTableSortChange"
        @onSizeChange="handleSizeChange"
        @onPageChange="handlePageChange"
        @onClearAndRefresh="onClearAll"
        @onRefresh="onRefresh"
        @onSelectionChange="onSelectionChange"
      >
        <template #tableControl></template>
        <template v-for="column in tableColumns">
          <el-table-column
            v-if="column.prop !== 'controlColumn' && column.showColumn"
            v-bind="column"
            :show-overflow-tooltip="true"
            :key="column.prop"
          >
            <template slot-scope="scope">
              <span v-if="column.prop === 'hidden'">
                <el-tag type="success" v-show="!scope.row.hidden"
                  >已显示</el-tag
                >
                <el-tag type="danger" v-show="scope.row.hidden">已隐藏</el-tag>
              </span>
              <span v-else-if="column.prop === 'createdAt'">
                {{ scope.row.createdAt | date }}
              </span>
              <span v-else-if="column.prop === 'lastModifiedAt'">
                {{ scope.row.lastModifiedAt | date }}
              </span>
              <span v-else-if="column.prop === 'seriesNameCn'">
                {{ scope.row.seriesNameCn }}/{{ scope.row.seriesNameEn }}
              </span>
              <span v-else-if="column.prop === 'effectNameCn'">
                {{ scope.row.effectNameCn }}/{{ scope.row.effectNameEn }}
              </span>
              <span v-else-if="column.prop === 'colorNameCn'">
                {{ scope.row.colorNameCn }}/{{ scope.row.colorNameEn }}
              </span>
              <!-- <span v-else-if="column.prop === 'specifications'">
                {{ scope.row.specifications.map((s) => s.name).join(",") }}
              </span> -->
              <span v-else-if="column.prop === 'posterImageUrl'">
                <el-image
                  style="width: 50px; height: 50px"
                  :src="scope.row.posterImageUrl"
                  :preview-src-list="[scope.row.posterImageUrl]"
                >
                </el-image>
              </span>
              <span v-else-if="column.prop === 'coverImageUrl'">
                <el-image
                  style="width: 50px; height: 50px"
                  :src="scope.row.coverImageUrl"
                  :preview-src-list="[scope.row.coverImageUrl]"
                >
                </el-image>
              </span>

              <span v-else>{{ scope.row[column.prop] }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="column.prop === 'controlColumn' && column.showColumn"
            v-bind="column"
            :key="column.prop"
          >
            <template slot-scope="scope">
              <!-- <el-button
                @click.native.prevent="showDetail(scope.row, scope.$index)"
                type="text"
                size="small"
              >
                详情
              </el-button> -->
              <el-button
                v-if="scope.row.canUpdate && !scope.row.isEdit"
                @click.native.prevent="editRow(scope.row, scope.$index)"
                type="text"
                size="small"
              >
                修改
              </el-button>
              <el-button
                class="text-danger"
                v-if="!scope.row.isEdit"
                :disabled="!scope.row.canDelete"
                @click.native.prevent="DeleteRow(scope.row, scope.$index)"
                type="text"
                size="small"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </template>
        <!-- <template #multipleControl></template> -->
      </basic-table>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import BasicTable from "@/components/BasicTable";
import config from "@/config";
import { ShowApiError } from "@/request/error";
import { getOrderType, onClearFilter } from "@/utils/common";
import {
  GetPagesList,
  GetDeleteCheck,
  GetUpdateCheck,
  DeletePagesByIds,
} from "./api";

export default {
  name: "PageManagerList",
  components: {
    PageHeader,
    BasicTable,
  },
  data() {
    return {
      currentId: null,
      detailDialog: false,
      detailDialogTitle: "详情数据",
      formDialog: false,
      tableLoading: false,
      tableColumns: [],
      page: 0,
      size: config.TABLE_SIZE,
      total: 0,
      tableData: [],
      orderProp: "seq",
      orderDirection: "descending",
      orderPropObj: {},
      tableFilters: {},
      multipleSelected: [],
    };
  },
  created() {
    this.buildTableColumns();
    this.getTableData();
  },
  methods: {
    buildTableColumns(columns = []) {
      if (columns.length > 0) {
        this.tableColumns = columns;
      } else {
        this.tableColumns = [
          {
            prop: "hidden",
            label: "显示",
            showColumn: true,
            width: 100,
            align: "center",
            fixed: "left",
            sortable: "custom",
          },
          {
            prop: "id",
            label: "ID",
            width: 80,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "seq",
            label: "次序",
            width: 80,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "name",
            label: "名称",
            minWidth: 130,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "title",
            label: "标题",
            minWidth: 130,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "subTitle",
            label: "子标题",
            minWidth: 100,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "parentName",
            label: "父级页面",
            width: 130,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "icon",
            label: "页面图标",
            minWidth: 150,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "description",
            label: "页面描述",
            minWidth: 150,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },

          {
            prop: "createdByName",
            label: "创建人",
            width: 100,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "createdAt",
            label: "创建时间",
            width: 120,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "lastModifiedByName",
            label: "更新人",
            width: 100,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "lastModifiedAt",
            label: "更新时间",
            showColumn: true,
            width: 120,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "controlColumn",
            fixed: "right",
            align: "center",
            showColumn: true,
            label: "操作",
            width: 130,
          },
        ];
      }
    },
    handleSizeChange(val) {
      this.page = 0;
      this.size = val;
      this.getTableData();
    },
    handlePageChange(val) {
      this.page = val - 1;
      this.getTableData();
    },
    getTableOrderParams() {
      let orderPropName = "";
      if (this.orderProp !== "" && this.orderDirection !== null) {
        orderPropName = Object.prototype.hasOwnProperty.call(
          this.orderPropObj,
          this.orderProp
        )
          ? this.orderPropObj[this.orderProp]
          : this.orderProp;
        return `${orderPropName},${getOrderType(this.orderDirection)}`;
      } else {
        this.orderProp = "seq";
        this.orderDirection = "descending";
        return `${this.orderProp},${getOrderType(this.orderDirection)}`;
      }
    },
    filterParams() {
      return { ...this.tableFilters };
    },
    getTableData() {
      this.tableLoading = true;
      GetPagesList({
        page: this.page,
        size: this.size,
        sort: this.getTableOrderParams(),
        ...this.filterParams(),
      })
        .then((res) => {
          let { content, totalElements } = res.data;
          this.tableData = content;
          this.tableData = this.tableData.map((item) => {
            return {
              ...item,
              canUpdate: true,
              canDelete: true,
            };
          });
          this.tableData.forEach((item) => {
            this.onCheckUpdate(item);
            this.onCheckDelete(item);
          });
          this.total = totalElements;
          // 加载状态结束
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          ShowApiError(err, "拉取列表错误");
        });
    },
    onCheckUpdate(item) {
      GetUpdateCheck(item.id)
        .then((res) => {
          item.canUpdate = res.data;
        })
        .catch(() => {
          item.canUpdate = true;
        });
    },
    onCheckDelete(item) {
      GetDeleteCheck([item.id])
        .then((res) => {
          item.canDelete = res.data;
        })
        .catch(() => {
          item.canDelete = true;
        });
    },
    DeleteRow(row) {
      if (row.canDelete) {
        this.$confirm("此操作将永久删除该数据项, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            DeletePagesByIds([row.id]).then(() => {
              this.onRefresh();
              this.$message.success("操作成功!");
            });
          })
          .catch(() => {});
      } else {
        this.$message.warning("没有权限执行此操作!");
      }
    },
    onRefresh() {
      this.getTableData();
    },
    onSearch() {
      this.page = 0;
      this.getTableData();
    },
    onFilterChange(data) {
      this.tableFilters = data;
      this.page = 0;
      this.onRefresh();
    },
    onClearAll() {
      this.tableFilters = onClearFilter(this.tableFilters);
      this.orderProp = "seq";
      this.orderDirection = "descending";
      this.onSearch();
    },
    onTableSortChange({ prop, order }) {
      if (order === null) {
        this.orderProp = "seq";
        this.orderDirection = "descending";
      } else {
        this.orderProp = prop;
        this.orderDirection = order;
      }
      this.page = 0;
      this.onRefresh();
    },
    addRow() {
      this.$router.push({
        name: "PageManagerEdit",
        params: {
          id: null,
        },
      });
    },
    editRow(row) {
      if (row.canUpdate) {
        this.$router.push({
          name: "PageManagerEdit",
          params: {
            id: row.id,
          },
          query: {
            title: row.name,
          },
        });
        // this.$message.info("待接入");
      } else {
        this.$message.warning("没有权限执行此操作!");
      }
    },
    showDetail(row) {
      this.currentId = row.id;
      this.detailDialogTitle = `${row.nameCn} -${row.nameEn}`;
      this.detailDialog = true;
    },
    onSelectionChange(selections) {
      this.multipleSelected = selections;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>